<template>
  <div>
    <h3 class="mb-1 mt-1">
      Ready for Cetak
    </h3>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="warning"
      class="mb-1"
      :disabled="btnDownloadDisabled"
      @click="downloadExcel"
    >
      <b-spinner v-show="btnDownloadDisabled" small type="grow" />
      <feather-icon icon="DownloadCloudIcon" class="mr-50" />
      <span class="align-middle">Download All in Excel</span>
    </b-button>
    <!-- {{ items }} -->
    <vue-good-table
      :columns="columns"
      :rows="items"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <div v-if="props.column.field === 'id'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.id }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="download(props.row)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Download Foto</span>
              </b-dropdown-item>
              <b-dropdown-item @click="downloadQR(props.row)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Download QR</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <!-- Column: Jml order -->
        <div
          v-else-if="props.column.field === 'daftar_pencetakan'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{
            props.row.daftar_pencetakan.length
          }}</span>
        </div>
      </template>
    </vue-good-table>
    <b-overlay :show="processing" no-wrap>
      <template #overlay>
        <b-spinner variant="primary" label="Text Centered" />
      </template>
    </b-overlay>
  </div>
</template>

<script>
import {
  // BAvatar,
  // BBadge,
  // BPagination,
  // BFormGroup,
  // BFormInput,
  // BFormSelect,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BSpinner,
  BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { api, download } from '@/hap-sia/setup'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueGoodTable,
    // BAvatar,
    // BBadge,
    // BPagination,
    // BFormGroup,
    // BFormInput,
    // BFormSelect,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BSpinner,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      test: null,
      processing: false,
      btnDownloadDisabled: false,
      pageLength: 20,
      items: [],
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Nama Lengkap',
          field: 'nama_lengkap',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Nama',
          },
        },
        {
          label: 'Nama KTPA',
          field: 'nama_ktpa',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Nama KTPA',
          },
        },
        {
          label: 'NIA',
          field: 'nia',
          filterOptions: {
            enabled: true,
            placeholder: 'Search NIA',
          },
        },
        {
          label: 'Berlaku',
          field: 'tanggal_berakhir',
        },
        {
          label: 'DPC',
          field: 'dpn_dpc.nama',
          filterOptions: {
            enabled: true,
            placeholder: 'Search DPC',
          },
        },
        {
          label: 'Cetak',
          field: 'daftar_pencetakan',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
    }
  },
  async mounted() {
    this.processing = true
    try {
      const result = await api().get(
        'search/biodata/ready-cetak?tanggal_berakhir=2022-01-01',
      )
      result.data.items.forEach(element => {
        this.items.push(element)
      })
    } catch (error) {
      alert(error.response.data.message)
    }
    this.processing = false
  },
  methods: {
    testRender(instance, td, row, col, prop, value) {
      // console.log(value)
      // eslint-disable-next-line no-param-reassign
      td.innerHTML = value.length
    },
    async download(row) {
      // const fileUrl = `${fileServerUrl}/biodata/${this.masterDokumen.biodata_id}/${this.masterDokumen.data[filename]}`
      // window.open(fileUrl)

      // alert(this.masterDokumen.data[filename])
      const filename = row.foto
      const biodataID = row.id

      try {
        this.processing = true
        // console.log(row)
        // await new Promise(r => setTimeout(r, 2000))
        const result = await download().get(
          `biodata/download/${biodataID}/${filename}`,
        )
        // console.log(result)
        // this.fileQR = result.data.url
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename) // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        alert(error)
      }
      this.processing = false
    },
    async downloadExcel() {
      try {
        this.processing = true
        // console.log(row)
        // await new Promise(r => setTimeout(r, 2000))
        const result = await download().get(
          'search/biodata/ready-cetak?tanggal_berakhir=2022-01-01&export=xls',
        )
        // console.log(result)
        // this.fileQR = result.data.url
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'ready_cetak.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        alert(error)
      }
      this.processing = false
    },
    async downloadQR(row) {
      // alert(filename)
      this.processing = true
      const biodataID = row.id
      try {
        const result = await download().get(`qr/code/${biodataID}/download`)
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `qr_${row.nia}.png`) // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        alert(error)
      }
      this.processing = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
